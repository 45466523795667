/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LogStreamResponse = {
    properties: {
        entries: {
            type: 'array',
            contains: {
                type: 'LogStreamEntry',
            },
            isRequired: true,
        },
        num_filtered: {
            type: 'number',
            isRequired: true,
        },
        next_stream_id: {
            type: 'string',
        },
    },
} as const;
