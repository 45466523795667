/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ExtractResponse = {
    properties: {
        fields: {
            type: 'array',
            contains: {
                type: 'ExtractedField',
            },
            isRequired: true,
        },
        job_id: {
            type: 'string',
        },
        metadata: {
            properties: {
            },
        },
        log_stream_key: {
            type: 'string',
        },
    },
} as const;
