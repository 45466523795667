/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AnalyticSummary = {
    properties: {
        at: {
            type: 'AnalyticLineItem',
            isRequired: true,
        },
        since: {
            type: 'AnalyticLineItem',
        },
    },
} as const;
