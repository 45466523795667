/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LogStreamRequest = {
    properties: {
        timestamp: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        next_stream_id: {
            type: 'string',
        },
        limit: {
            type: 'number',
        },
        max_level: {
            type: 'number',
        },
    },
} as const;
