
import ldSet from "lodash/set"
import {parseISO,formatISO,isValid} from "date-fns"
import {absoluteDateOnly} from "~/utils/dates"


export interface FilterSpec {
    label:string
    //Name of field that holds value.
    field:string
    //Graphql fields that this will target (default sto field)
    query_targets?:string[]
    query_provider?:(f:FilterSpec, value:any) => any[]
    // IS this a range
    range?:boolean
    //Identification of component to use. (string or cnstructor)
    component:string|Function
    itemProps?:Record<string,any>
    //Props to pass to component
    props?:Record<string,any>
    alias?:string // Alternate name for spec encoding
    multiple?:boolean;
    operator?:string;
    adminOnly?:boolean;
    hidden?:boolean
}


/**
 * Condition Value based on type
spec, */
export function scalarValue(spec:FilterSpec,value:any):any {
    switch(spec.component){
        case "date":
        case Date:
            /*if(!value) return value;
            value = (value instanceof Date)?value: parseISO(value)
            return formatISO(value)*/
            return value;
        default:
            return value;
    }
}

/**
 * Given a URL friend version of a value produce a JS version
 */
export function parseScalarValue(spec:FilterSpec,value:any):any {
    if(!value) return value;
    switch(spec.component){
        case "date":
        case Date:
            let parsed=parseISO(value)
            if(isValid(parsed)) return parsed
        default:
            return value;
    }
}
let NEGATE = "#negate"
/**
 *
 */
export function makeQuery(spec:FilterSpec,value:any):any[]{
    if(value === undefined) return []
    if(spec.range){
        if(!Array.isArray(value)) return []
        let terms = value.flatMap((item,index) => {
            if(!item) return []
            let key = (index == 0)?"_gte":"_lte";
            return [{[key]:scalarValue(spec,item)}]
        })
        return terms
    }
    if(Array.isArray(value)){
        return [{ _in: value.map(x => scalarValue(spec,x))}]
    }
    let operator = spec.operator ?? "_eq"
    value = scalarValue(spec,value);
    if(operator == "is_null"){
        return [{[NEGATE]:{}}]
    }
    return [{ [operator]:value}]
}


/**
 * Plain Encoding of filter values
 * Range -> RangeClause (in python)
 * Arrays -> Array
 * Values -> Values
 */
export function makePlain(spec:FilterSpec,value:any):any{
    if(value === undefined) return undefined
    if(spec.range){
        if(!Array.isArray(value)) return undefined
        let out:any = {}
        value.flatMap((item,index) => {
            if(!item) return []
            let key = (index == 0)?"gte":"lte";
            return out[key] = scalarValue(spec,item)
        })
        return out
    }
    if(Array.isArray(value)){
        return value.map(x => scalarValue(spec,x))
    }
    return scalarValue(spec,value);
}

/**
 * Constructa  Graphql Query from the  the filter and the spec
 */
export function constructGraphqlFilter(obj:any,specs:FilterSpec[]):object|undefined {
    let clauses:any[] = []
    for(let f of specs){
        let fld = f.field;
        let val=obj[f.field];
        if(val){
            let query = f.query_provider?.(f,val) ?? makeQuery(f,val);
            //No items fo rthis segment
            if(query.length == 0)  continue
            let target_fields =f.query_targets || [f.field]
            let out:any[] = target_fields.flatMap(fieldName => {
                let segments = query.map(q => {
                    let isNegate =  NEGATE in q;
                    if(isNegate) q = q[NEGATE]
                    let out = ldSet({},fieldName,q)
                    if(isNegate) out = {_not:out}
                    return out
                })
                if(segments.length > 1) return [{_and:segments}]
                return segments
            })
            if(out.length > 1){
                out = [{_or:out}]
            }
            clauses.push(...out)
        }
    }
    if(clauses.length == 0) return
    return { _and:clauses }
}



/**
 * Constructa  Graphql Query from the  the filter and the spec
 */
export function constructPlainFilter(obj:any,specs:FilterSpec[]):object|undefined {
    let plain:any = {}
    for(let f of specs){
        let fld = f.field;
        let val=obj[f.field];
        if(val){
            let query = makePlain(f,val);
            //No items fo rthis segment
            if(query ==  null || typeof query == "undefined")  continue
            plain[f.field]=query
        }
    }
    return plain
}

/**
 * Condition Value based on type
 */
export function encodeScalarValue(spec:FilterSpec,value:any):any {
  if (Array.isArray(value)) return value.map(x => encodeScalarValue(spec,x))
    switch(spec.component){
        case "date":
        case Date:
        if(value instanceof Date){
          value = absoluteDateOnly(value)
        }else {
          /*if(!value) return value;
            value = (value instanceof Date)?value: parseISO(value)
            return formatISO(value)*/
          return value;
        }
        default:
            return value;
    }
}

/**
 * Encode the Filter Specification
 */
export function encodeFilterSpec(obj:any,specs:FilterSpec[],prefix:string=""):Record<string,any>{
    return Object.fromEntries(specs.flatMap(spec => {
        let val = obj[spec.field]
        if(val === undefined) return []
        let key = spec.alias || spec.field;
        val = encodeScalarValue(spec,val)
        if(Array.isArray(val))  val = val.join(",")
        return [[prefix + key,val]]
    }))
}

/**
 * Decode a filter spec that has been encoding into a querystring url
 */
export function decodeFilterSpec(obj:any,specs:FilterSpec[],prefix:string=""):Record<string,any> {
    return Object.fromEntries(specs.flatMap(spec => {
        let key = prefix +  spec.alias
        if(!spec.alias || !(key in obj)) key = prefix +spec.field
        let val =  obj[key]
        if(val == "" || val == undefined) return []
        if(spec.multiple || spec.range) val = val.split(/,/);
        if(Array.isArray(val)) val = val.map(x => parseScalarValue(spec,x))
        else val = parseScalarValue(spec,val)
        return [[spec.field,val]]
    }))
}


export enum FilterKeyPrefix {
    Transactions= "f.",
        Events="e.",
        Summary="s."
}
