import {format,formatRelative,parseISO } from "date-fns"

function _parseISO(x:string|Date) {
    if(x instanceof Date) return x
    return parseISO(x)
}

export const ERR_BAD_DATE = "---"

export function relativeNow(x:string|Date) {
    try{
        if(x === null || x === undefined) return "---"
        return formatRelative(_parseISO(x),new Date());
    }catch(e){
        console.log("Bad Date value ->",x)
        return ERR_BAD_DATE
    }
}

export function absoluteDate(x:Date|string,defValue:string = "---"){
    try {
        if(x === null || x === undefined) return defValue
        return format(_parseISO(x),"yyyy-MM-dd @ HH:mm")
    }catch(e){
        console.log("Bad Date value ->",x)
        return ERR_BAD_DATE
    }
}

export function absoluteDateOnly(x:Date|string,defValue:string = "---"){
    try {
        if(x === null || x === undefined) return defValue
        return format(_parseISO(x),"yyyy-MM-dd")
    }catch(e){
        console.log("Bad Date value ->",x)
        return ERR_BAD_DATE
    }
}


export function localDate(dateStr:Date|string):Date {
    if(dateStr instanceof Date) return dateStr
    if(dateStr.indexOf("T") == -1) dateStr =  dateStr + "T00:00";
    return new Date(dateStr)
}

