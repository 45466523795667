/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AnalyticLineItem = {
    properties: {
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        details: {
            type: 'dictionary',
            contains: {
                type: 'AnalyticDetail',
            },
        },
    },
} as const;
