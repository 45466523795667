/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiWorkflow } from '../models/ApiWorkflow';
import type { ApiWorkflowType } from '../models/ApiWorkflowType';
import type { InputApiWorkflow } from '../models/InputApiWorkflow';
import type { LogStreamRequest } from '../models/LogStreamRequest';
import type { LogStreamResponse } from '../models/LogStreamResponse';
import type { SymbolMappingResponse } from '../models/SymbolMappingResponse';
import type { SymbolMappingUpdate } from '../models/SymbolMappingUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowService {

    /**
     * Get Workflow Type
     * @param id
     * @param host
     * @returns ApiWorkflowType Successful Response
     * @throws ApiError
     */
    public static getWorkflowType(
        id: string,
        host?: string,
    ): CancelablePromise<ApiWorkflowType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflow-type/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workflow
     * @param id
     * @param host
     * @returns ApiWorkflow Successful Response
     * @throws ApiError
     */
    public static getWorkflow(
        id: string,
        host?: string,
    ): CancelablePromise<ApiWorkflow> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Workflow
     * Add a Workflow
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiWorkflow Successful Response
     * @throws ApiError
     */
    public static addWorkflow(
        requestBody: InputApiWorkflow,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Workflow
     * Update a Workflow
     * @param ulid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiWorkflow Successful Response
     * @throws ApiError
     */
    public static updateWorkflow(
        ulid: string,
        requestBody: InputApiWorkflow,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Symbol Mappings
     * @param ulid
     * @param host
     * @returns SymbolMappingUpdate Successful Response
     * @throws ApiError
     */
    public static getSymbolMappings(
        ulid: string,
        host?: string,
    ): CancelablePromise<SymbolMappingUpdate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/act/symbol-mapping',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Symbol Suggestions
     * @param ulid
     * @param host
     * @returns SymbolMappingUpdate Successful Response
     * @throws ApiError
     */
    public static getSymbolSuggestions(
        ulid: string,
        host?: string,
    ): CancelablePromise<SymbolMappingUpdate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/act/symbol-suggestions',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Symbol Mapping
     * @param id
     * @param requestBody
     * @param host
     * @returns SymbolMappingResponse Successful Response
     * @throws ApiError
     */
    public static putSymbolMapping(
        id: string,
        requestBody: SymbolMappingUpdate,
        host?: string,
    ): CancelablePromise<SymbolMappingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workflows/{id}/act/symbol-mapping',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execute Report
     * Generate The report for a workflow
     * @param id
     * @param altReport
     * @param format
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static executeReport(
        id: string,
        altReport?: string,
        format?: ('doc' | 'json' | 'json+split' | 'csv' | 'xlsx'),
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{id}/act/generate_report',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'alt_report': altReport,
                'format': format,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Flagupdate
     * @param id
     * @param requestBody
     * @param host
     * @returns ApiWorkflow Successful Response
     * @throws ApiError
     */
    public static flagUpdate(
        id: string,
        requestBody: Record<string, boolean>,
        host?: string,
    ): CancelablePromise<ApiWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{id}/act/flag',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Confirm Workflow
     * @param id
     * @param documentId
     * @param host
     * @returns ApiWorkflow Successful Response
     * @throws ApiError
     */
    public static confirmWorkflow(
        id: string,
        documentId: string,
        host?: string,
    ): CancelablePromise<ApiWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{id}/act/confirm',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Log Stream
     * @param ulid
     * @param requestBody
     * @param host
     * @returns LogStreamResponse Successful Response
     * @throws ApiError
     */
    public static getLogStream(
        ulid: string,
        requestBody: LogStreamRequest,
        host?: string,
    ): CancelablePromise<LogStreamResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/logs',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
