/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserSummaryResponse = {
    description: `User Summary Resposne`,
    properties: {
        targetUnit: {
            type: 'ApiInstrument',
            isRequired: true,
        },
        labels: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        at: {
            type: 'one-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
            isRequired: true,
        },
        since: {
            type: 'one-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        core: {
            type: 'array',
            contains: {
                type: 'LineItem',
            },
        },
        esti: {
            type: 'array',
            contains: {
                type: 'LineItem',
            },
        },
        close: {
            type: 'array',
            contains: {
                type: 'LineItem',
            },
        },
        unexplained: {
            type: 'array',
            contains: {
                type: 'LineItem',
            },
        },
        rates: {
            type: 'array',
            contains: {
                type: 'ExchangeRateEntry',
            },
        },
        analytic: {
            type: 'AnalyticSummary',
        },
        net_worth: {
            type: 'NetWorthSummary',
        },
        explain_key: {
            type: 'string',
        },
        total_errors: {
            type: 'number',
        },
        user_errors: {
            type: 'array',
            contains: {
                type: 'UserSummaryError',
            },
        },
        last_modified: {
            type: 'string',
            format: 'date-time',
        },
        cache_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        refs: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
    },
} as const;
