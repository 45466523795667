
import {
    Component,Vue,Prop,Watch,ModelSync, Getter
} from "nuxt-property-decorator"
import {VNode,CreateElement} from "vue"
import {VChip,VIcon} from "vuetify/lib"
import {ApiEvent,ApiProfile,EventType} from "~/schemas/gen"
import gql from "graphql-tag"
import {Events_Bool_Exp,Events} from "~/models/graphql"
import {
    eventSearchExp,labelForEventType,
    ApiEventNode,fragApiEventNodeDeepFields,
} from "~/core/events"
import {transactionSearchExp} from "~/core/transactions"
import {describeTransaction,transactionLine2,transactionLine2Segments}  from "~/components/transaction/render"
import uniqBy from "lodash/uniqBy"
import {CARET} from "~/core/common/render"
import {
    autoSegmenter,
    fragCapitalCommitmentRenderable,
    fragNewTransactionRenderable
} from "~/core/events/render"
import {itemExplorationLink} from "~/core/common/input"
import * as routing from "~/core/routing"




/*
        ...RenderableCapitalCommitment @include (if: $withCC)
        ...RenderableNewTransaction @include (if: $withNT)
*/
const searchEvents= gql`
query searchEvents($eventQ:events_bool_exp,$limit:Int,$order_by:[events_order_by!],
$withCC:Boolean=false,$withNT:Boolean=false){
    events(where:$eventQ,limit:$limit,order_by:$order_by){
        ...ApiEventNodeDeepFields
        ...RenderableCapitalCommitment @include (if: $withCC)
        ...RenderableNewTransaction @include (if: $withNT)
    }
}
${fragApiEventNodeDeepFields}
${fragCapitalCommitmentRenderable}
${fragNewTransactionRenderable}
`;




export type EventDetailProvider = (x:Partial<Events>) => string[]

@Component({
           components:{VChip,VIcon}
})
class EventItem extends Vue {
    /**
     * EntityItem -
     */
    @Prop() item!:ApiEventNode
    @Prop({default:false,type:Boolean}) readonly extended!:boolean
    @Prop({type:Function}) readonly segmenter?:EventDetailProvider
    @Getter("user/profile") profile!:ApiProfile|null
    @Getter("user/systemUserId") systemUserId!:string|null

    render(h:CreateElement){
        let {item,extended,profile,systemUserId} = this;
        let children:(VNode|string)[] = [
            describeEvent(item as any) ," "
        ]
        let chipChildren:(VNode|string)[] = []

        children.push(" ")
        if(this.extended){
            if(this.segmenter){
                let segments = this.segmenter(item)
                children.push(h("h6",{},segments.flatMap((x,i) => {
                    if(i == 0) return [x]
                    return [CARET,x]
                })))
            }else {
                if(item.initial_transaction|| item.child_transaction?.[0]){
                    children.push(transactionLine2((item.child_transaction?.[0] || item.initial_transaction) as any,h,{instrument:true}))
                }else { children.push("???"); }
            }
        }

        let body = h("div",{ class:{"event-input-select":true}},children)
        return itemExplorationLink(h,routing.forEvent(item),body)
    }
}





/**
 * Describe -  Event
 */
export function describeEvent(item:ApiEventNode):string{
    if(item.description && item.description.length >0) return item.description
    if(item.identifier) return item.identifier;
    let lbl = labelForEventType(item.event_type as any)
    let lowLbl = (item.uuid||"").substring(0,6)
    return `${lbl} - #${item.ordinal} \u25b8 ${lowLbl}`
}

/**
 * Instrument Input component with combobox and autocomplete functionality
 */
@Component({
           inheritAttrs:true,
           components:{EventItem},
           apollo:{
               searchEvents:{
                   query: searchEvents,
                       variables():any {
                           let q = eventSearchExp(this.search)
                           let rawQ:Events_Bool_Exp ={
                               _or:[
                                   {initial_transaction:transactionSearchExp(this.search)},
                                   q,
                                   {parent_event:q}
                               ]
                           }
                           let {filterQuery} = this
                           if(filterQuery){
                               if(rawQ._and) rawQ._and.push(filterQuery)
                               else{
                                   rawQ = { _and:[rawQ,filterQuery]}
                               }
                           }
                           return {eventQ:rawQ,...this.commonArgs}
                       },
                       update: data => data.events,
                       skip() {
                            let {search} = this
                           return !search || this.search?.trim?.()?.length ==0
                       },
                   debounce:400
               },
                   initialEvent:{
                       query:searchEvents,
                           variables():any {
                               let uuid!:string
                               if(typeof(this.initValue) == "string") uuid = this.initValue
                               else uuid = this.initValue.id
                               return {eventQ:{uuid:{_eq: uuid}},...this.commonArgs}
                           },
                           update: data => data.events[0],
                           skip(){
                               return !this.initValue
                           }
                   },
               recentEvents:{
                   query:searchEvents,
                   update: data => data.events,
                   variables():any {
                       let {filterQuery} = this;
                       let baseQ=  {limit:5,order_by:{updated_time:"desc"},
                           ...this.commonArgs }
                       if(filterQuery) baseQ.eventQ = filterQuery
                       return baseQ
                   }
               }
           }
})
export default class EventInput extends Vue {
    @ModelSync("value","input") input!:string|ApiEventNode;
    //@Prop({default: "name"}) outType!:InstrumentRefType
    @Prop({default:() => [],type:[Array,String]}) readonly filterType!:EventType|EventType[]
    @Prop({default:() => ({}),type:Object}) readonly queryOpts!:Record<string,any>
    @Prop({default:false,type:[Object,Boolean]}) readonly filterClause!:Events_Bool_Exp|false
    @Prop({}) vjsfContext!:any; //Optional Vjsf slot context
    @Prop() label!:string|null;
    @Prop() errorMessages!:any[]
    @Prop({default:false}) objectMode!:boolean;
    @Prop({default:autoSegmenter,type:Function}) readonly segmenter?:EventDetailProvider


    search:string =  ""
    loading:boolean = false
    searchEvents:ApiEventNode[] = []
    recentEvents:ApiEventNode[] = []
    initialEvent:ApiEventNode|null = null
    initValue:ApiEventNode|string|null = null
    itemError:Error|any|null = null
    fixed:boolean = false

    async mounted(){
        let {input} = this
        this.initValue = input;
    }

    get displayLabel():string{
        return this.label || this.vjsfContext?.label
    }
    get items():ApiEventNode[]{
        let entries:ApiEventNode[] = []
        let {input:value,search} = this;
        if(this.searchEvents.length > 0) entries.push(...this.searchEvents)
        if(this.initialEvent) entries.push(this.initialEvent)
        if(this.recentEvents.length >  0 ) entries.push(...this.recentEvents)
        return uniqBy(entries,(x:any) => x.uuid)
    }
    get commonArgs():object {
        return this.queryOpts
    }
    get filterQuery():Events_Bool_Exp|null {
        let clauses:Events_Bool_Exp[]= [];
        if(this.filterType ){
            let fType:EventType[] =Array.isArray(this.filterType)?this.filterType:[this.filterType];
            if(fType.length > 0 ){
                clauses.push({
                             event_type:{ _in: fType }
                })
            }
        }
        if(this.filterClause){
            clauses.push(this.filterClause)
        }
        if(clauses.length  == 0) return null
        return {_and:clauses }
    }

    get selectInput():string|null {
        if(!this.input) return null;
        if(typeof(this.input) == "string") return this.input;
        return  this.input.uuid!
    }

    get passedListeners():object{
        let {input,...captured} = this.$listeners
        return   captured;
    }


    onItemSelected(x:ApiEventNode|null){
        if( x == null) {
            this.$emit("input",null)
            return
        }
        let ret!:any

        if(this.objectMode){
            ret =x
        }else ret = x.uuid!

        this.$emit("input",ret)
    }
    onKeyDown($event:KeyboardEvent){
        if($event.keyCode == 13){
            //Pressing retunr will choose the first suggestion
            $event.stopPropagation();
            $event.preventDefault();
            this.selectFirst();
        }
    }

    selectFirst(){
        if(this.items.length > 0){
            this.onItemSelected(this.items[0])
        }
    }

    compareEvent(x:ApiEventNode|string,y:ApiEventNode|string):boolean {
        if(x === y ||  x == y  ) return true
        if(x == null || y == null) return false;
        if(typeof(x) == "string"){
            if(typeof(y) == "object") return x == y.uuid
            return false
        }
        if(typeof(y) == "string") return y === x.uuid
        return false

        ;
    }
    getItemText(item:ApiEventNode){
        if(typeof item === "string") return item
        let trans:any = item.initial_transaction ?? item.child_transaction?.[0]
        let segments = trans?transactionLine2Segments(trans,{instrument:true}):[]
        return describeEvent(item) + segments.join(" ")
    }

    getItemValue(x:ApiEventNode){
        return x.uuid
    }
}

